import React, { useState, useEffect, useRef } from "react";
import {
  subscribeToTradeHistory,
  unsubscribeFromOrderBook,
} from "../../socketService"; // Import the socket service
import { useParams } from 'react-router-dom';

const MarketTrade = () => {
  const { market } = useParams();
  console.log("market",market)
  const [tradeHistory, setTradeHistory] = useState([]);
  const [visibleRows, setVisibleRows] = useState(15); // Initial visible rows
  const containerRef = useRef(null);

  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === market && data.tradeHistory) {
        setTradeHistory(data.tradeHistory);
      } else {
        setTradeHistory([]);
      }
    };

    subscribeToTradeHistory(market, handleTradeHistoryUpdate);

    // Cleanup function to unsubscribe when component unmounts
    return () => {
      unsubscribeFromOrderBook(market);
    };
  }, [market]);

  // Function to handle scroll event
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // If scrolled to bottom, load more rows
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setVisibleRows((prevRows) => Math.min(prevRows + 15, tradeHistory.length));
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [tradeHistory]);

  // Get the visible trade data
  const visibleTradeHistory = tradeHistory.slice(0, visibleRows);

  return (
    <div
      ref={containerRef}
      style={{ maxHeight: "300px", overflowY: "auto" }} // Add scroll behavior
    >
      <table className="w-100 table table-borderless mb-0">
        <thead className="thead-shadow">
          <tr className="text-muted">
            <th>Size</th>
            <th>Price (USD)</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {visibleTradeHistory.length > 0 ? (
            visibleTradeHistory.map((trade, index) => (
              <tr
                key={index}
                className={
                  trade.trade_type === "buy" ? "text-success" : "text-danger"
                }
                style={{ lineHeight: "1.2", padding: "5px 0" }} // Reducing row spacing
              >
                <td style={{ padding: "5px" }}>{trade.volume}</td>
                <td style={{ padding: "5px" }}>{trade.price}</td>
                <td style={{ padding: "5px" }}>
                  {new Date(parseInt(trade.trade_date)).toLocaleTimeString()}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No trades available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MarketTrade;
