import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  subscribeToTradeHistory,
  unsubscribeFromOrderBook,
} from "../../socketService"; // Import the socket service
import "./tradedata24h.css"; // Import your custom CSS
import { fetchTradingPairs } from "../../api"; // Adjust the path according to your file structure

const Tradedata24h = () => {
  const { market } = useParams();
  const navigate = useNavigate();
  const [last24data, setLast24Data] = useState([]);
  const [lowPrice, setLowPrice] = useState(null);
  const [highPrice, setHighPrice] = useState(null);
  const [lastPrice, setLastPrice] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [pairs, setPairs] = useState([]); // Store all trading pairs
  const [selectedCurrency, setSelectedCurrency] = useState("INR"); // Default currency
  const [dropdownVisible, setDropdownVisible] = useState(false); // Toggle dropdown visibility
  const [isLoading, setIsLoading] = useState(false);

  // Handle WebSocket updates for trade history
  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === market && data.tradeHistory) {
        const now = Date.now();
        const last24Hours = 24 * 60 * 60 * 1000;
        setSymbol(data.symbol);

        // Filter trade history for the last 24 hours
        const filteredData = data.tradeHistory.filter((trade) => {
          const tradeTimestamp = parseInt(trade.trade_date, 10);
          return now - tradeTimestamp <= last24Hours;
        });

        setLast24Data(filteredData);

        // Calculate low, high, and last prices in the last 24 hours
        if (filteredData.length > 0) {
          const prices = filteredData.map((trade) => parseFloat(trade.price));
          setLowPrice(Math.min(...prices));
          setHighPrice(Math.max(...prices));
          setLastPrice(prices[prices.length - 1]); // Last price is from the most recent trade
        } else {
          setLowPrice(0);
          setHighPrice(0);
          setLastPrice(0);
        }
      } else {
        setLast24Data([]);
        setLowPrice(0);
        setHighPrice(0);
        setLastPrice(0);
      }
    };

    subscribeToTradeHistory(market, handleTradeHistoryUpdate);

    return () => {
      unsubscribeFromOrderBook(market);
    };
  }, [market]);

  // Fetch all trading pairs when dropdown is shown
  const GetPairAllCoinsInfo = async () => {
    setIsLoading(true);
    const response = await fetchTradingPairs();
    if (response) {
      setIsLoading(false);
      const pairData = response || [];
      setPairs(pairData); // Store all trading pairs data
    }
  };

  // Handle dropdown visibility
  const toggleDropdown = () => {
    if (!dropdownVisible) {
      GetPairAllCoinsInfo(); // Fetch trading pairs when showing the dropdown
    }
    setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
  };

  // Handle pair selection to navigate to a specific trading pair
  const handlePairClick = (pair) => {
    console.log("pair", pair);
    setDropdownVisible(false); // Close the dropdown after selecting
    navigate(`/spot/${pair}`); // Navigate to the selected trading pair
  };

  // Handle currency selection
  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency); // Update the selected currency
  };

  return (
    <>
      <div className="custom-card">
        <div className="grid-container">
          {/* Market Dropdown Section */}
          <div className="grid-item">
            <p className="text-market" onClick={toggleDropdown}>
              Market {dropdownVisible ? "▲" : "▼"}
            </p>
            <p>{market}</p>

            {/* Dropdown for pairs */}
            {dropdownVisible && (
              <div className="dropdown">
                <div className="currency-card-container">
                  <div
                    className={`currency-card ${
                      selectedCurrency === "TRX" ? "selected" : ""
                    }`}
                    onClick={() => handleCurrencyClick("TRX")}
                  >
                    TRX
                  </div>
                  <div
                    className={`currency-card ${
                      selectedCurrency === "INR" ? "selected" : ""
                    }`}
                    onClick={() => handleCurrencyClick("INR")}
                  >
                    INR
                  </div>
                  <div
                    className={`currency-card ${
                      selectedCurrency === "USDT" ? "selected" : ""
                    }`}
                    onClick={() => handleCurrencyClick("USDT")}
                  >
                    USDT
                  </div>
                  <div
                    className={`currency-card ${
                      selectedCurrency === "ETH" ? "selected" : ""
                    }`}
                    onClick={() => handleCurrencyClick("ETH")}
                  >
                    ETH
                  </div>
                </div>
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  pairs[selectedCurrency]?.map((pair, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() =>
                        handlePairClick(pair.symbol + `-${selectedCurrency}`)
                      }
                    >
                      <img
                        src={pair.icon}
                        alt={pair.market}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      <span className="pair-symbol">
                        {pair.symbol + `-${selectedCurrency}`}
                      </span>
                      <span className="pair-price">{pair.price}</span>

                      <span
                        className="dropdown-price"
                        style={{ marginLeft: "10px" }}
                      >
                        {pair[`${selectedCurrency}_price`]}{" "}
                      </span>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          {/* Price Display Section */}
          <div className="grid-item">
            <p className="text-last-price">Last Price</p>
            <p>{lastPrice !== null ? lastPrice.toFixed(2) : "N/A"}</p>
          </div>
          <div className="grid-item">
            <p className="text-low-price">Low</p>
            <p>{lowPrice !== null ? lowPrice.toFixed(2) : "N/A"}</p>
          </div>
          <div className="grid-item">
            <p className="text-high-price">High</p>
            <p>{highPrice !== null ? highPrice.toFixed(2) : "N/A"}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tradedata24h;
