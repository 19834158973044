import React from "react";
import "./wallet.css";

function OrderTable({ history }) {
  console.log("history",history)
  return (
    <div className="coin-history-container">
      <h4 className="heading">Coin Deposit History</h4>
      <div className="coin-history-cards">
        {history.length > 0 ? (
          history.map((order) => (
            <div key={order._id} className="card">
              <div className="card-row">
                <div>
                  <strong>transection_id:</strong> {order.transection_id}
                </div>
              </div>
              <div className="card-row">
                <div>
                  <strong>Currency:</strong> {order.symbol}
                </div>
                <div>
                  <strong>Status:</strong>{" "}
                  {order.status === 0 ? "pending" : "Successfully"}
                </div>
                <div>
                  <strong>Amount:</strong> {order.amount}
                </div>
              
              </div>

              <div className="card-row">
                <div>
          

                  <strong>Time:</strong>{" "}
                  {new Date(order.updatedAt).toLocaleString()}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No Withdrawal history</div>
        )}
      </div>
    </div>
  );
}

export default OrderTable;
