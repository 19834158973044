import React from "react";
import "./wallet.css";

function DepositHistory({ history }) {
    const formatStatus = (status) => {
        return status ? "Completed" : "Pending";
    };

    return (
        <div className="coin-history-container">
            <h4 className="heading">INR Deposit History</h4>
            <div className="coin-history-cards">
                {history.length > 0 ? (
                    history.map((transaction) => (
                        <div key={transaction._id} className="card">
                           
                            <div className="card-row">
                                <div><strong>Amount:</strong> {transaction.amount}</div>
                                <div><strong>Fee:</strong> {transaction.fee}</div>
                            </div>
                            <div className="card-row">
                                <div className={transaction.status ? "status-completed" : "status-pending"}>
                                    <strong>Status:</strong> {formatStatus(transaction.status)}
                                </div>
                                <div><strong></strong> {new Date(transaction.createdAt).toLocaleString()}</div>

                            </div>
                            <div className="card-row">
                                <div><strong>Transaction ID:</strong> {transaction.transection_id}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No deposit history available</div>
                )}
            </div>
        </div>
    );
}

export default DepositHistory;
