import React, { useState, useEffect } from "react";
import { getInfo } from "../../api"; // Import the API function

function GetuserInfo() {
  const [userDetails, setUserDetails] = useState(null); // State to hold user details
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error messages

  // Fetch user details when the component mounts
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getInfo(); // Call the getInfo API function
        if (response.status === 200) {
          if (response.error) {
            setErrorMessage("Please verify your KYC."); // Set error message if KYC verification is needed
          } else {
            // Update the userDetails state with the API response
            setUserDetails(response.response); // Assuming response.response contains the user details
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        setErrorMessage("An error occurred while fetching user information.");
      }
    };

    fetchUserInfo(); // Call the function to fetch user info
  }, []); // Empty dependency array means this effect runs once on mount

  // If there's an error message, show it
  if (errorMessage) {
    return <h4>{errorMessage}</h4>;
  }

  // If userDetails is not yet loaded, show a loading message
  if (!userDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-inner profile">
      <div className="content-inner profile">
        <form action="#">
          <h4>User Profile</h4>
          <h6>Infomation</h6>

          <div className="form-group d-flex s1">
            <input
              type="text"
              className="form-control"
              value={userDetails.first_name}
            />
            <input
              type="text"
              className="form-control"
              value={userDetails.last_name}
            />
          </div>
          <div className="form-group d-flex">
            <input value={userDetails.email} />
            <div className="sl">
              <input value={userDetails.mobile_no} />
            </div>
          </div>

          <div className="form-group d-flex">
            <select className="form-control" id="exampleFormControlSelect2">
              <option>{userDetails.country}</option>
            </select>
            <div className="sl">
            <input value={userDetails.date_of_birth} />
            </div>
          </div>

          
        

       
        </form>
      </div>
    </div>
  );
}

export default GetuserInfo;
