import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import { getwallet, getorder, gettransaction,isTokenValid } from "../api";
import Overview from "../components/wallet/Overview";
import OrderTable from "../components/wallet/OrderTable";
import DepositHistory from "../components/wallet/DepositHistory";
import CoinDepositHistory from "../components/wallet/CoinDepositHistory";
import Withdraw_history from "../components/wallet/Withdraw_history";
import { useNavigate } from "react-router-dom";

function Wallet() {
  const [totalBalance, setTotalBalance] = useState(0);
  const [walletData, setWalletData] = useState([]);
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [inrHistory, setInrHistory] = useState([]);
  const [coinHistory, setCoinHistory] = useState([]);
  const [withdraw_historys, setwithdraw_historys] = useState([]);
  const navigate = useNavigate();
 
  useEffect(() => {
    async function fetchWalletData() {
      try {
        const response = await getwallet();
        setTotalBalance(response.total_balance_in_inr);
        setWalletData(response.data);
      } catch (error) {
        navigate("/login"); // Redirect to the login page if the token is invalid

        console.error("Error fetching wallet data:", error);
      }
    }

    async function fetchOrderData() {
      try {
        const response = await getorder();
        setBuyOrders(response.data.buyOrder);
        setSellOrders(response.data.sellOrder);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    }

    async function fetchTransaction() {
      try {
        const response = await gettransaction();
        setCoinHistory(response.data.deposite_history);
        setInrHistory(response.data.inr_history);
        setwithdraw_historys(response.data.withdraw_historys);

      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    }

    fetchWalletData();
    fetchOrderData();
    fetchTransaction();
  }, []);

  const tabs = [
    { id: 1, title: "Overview", component: <Overview totalBalance={totalBalance} walletData={walletData} /> },
    { id: 2, title: "Buy Orders", component: <OrderTable orders={buyOrders} orderType="Buy" /> },
    { id: 3, title: "Sell Orders", component: <OrderTable orders={sellOrders} orderType="Sell" /> },
    { id: 4, title: "INR Deposit", component: <DepositHistory history={inrHistory} /> },
    { id: 5, title: "Coin Deposit", component: <CoinDepositHistory history={coinHistory} /> },
    { id: 5, title: "Withdrawal history", component: <Withdraw_history history={withdraw_historys} /> },

  ];

  return (
    <div>
      <PageTitle heading="Wallet" title="Wallet" />
      <section className="wallet buy-crypto flat-tabs">
        <div className="container">
          <div className="row">
            <Tabs>
              <TabList>
                {tabs.map((tab) => (
                  <Tab key={tab.id}>{tab.title}</Tab>
                ))}
              </TabList>
              {tabs.map((tab) => (
                <TabPanel key={tab.id}>{tab.component}</TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Wallet;
