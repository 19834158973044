import React, { useState, useEffect, useRef } from "react";
import "./orderbook.css";
import {
  subscribeToOrderBook,
  unsubscribeFromOrderBook,
} from "../../socketService";
import { useParams } from "react-router-dom";

const Orderbook = ({ setQuantityPriceValue }) => {
  const [orderBookData, setOrderBookData] = useState({ asks: {}, bids: {} });
  const askOrdersRef = useRef(null);
  const { market } = useParams();
  console.log("marketaa",market)
  useEffect(() => {
    const handleOrderBookUpdate = (data) => {
      if (data && data.asks && data.bids) {
        setOrderBookData(data);
      } else if (data.updatedOrderBook) {
        setOrderBookData(data.updatedOrderBook);
      } else {
        setOrderBookData([]);
      }
    };

    subscribeToOrderBook(market, handleOrderBookUpdate);

    return () => {
      unsubscribeFromOrderBook(market);
    };
  }, [market]);

  useEffect(() => {
    if (askOrdersRef.current) {
      askOrdersRef.current.scrollTop = askOrdersRef.current.scrollHeight;
    }
  }, [orderBookData]);

  const asksArray = Object.entries(orderBookData.asks || {})
    .map(([price, quantity]) => [parseFloat(price), quantity])
    .sort((b, a) => a[0] - b[0]);

  const bidsArray = Object.entries(orderBookData.bids || {})
    .map(([price, quantity]) => [parseFloat(price), quantity])
    .sort((a, b) => b[0] - a[0]);

  return (
    <div className="row">
      <div className="card custom-card">
        {" "}
        {/* Using theme-based card styles */}
        <div className="ch-table">
          <div className="ask-orders">
            <h8>Ask Orders</h8>
            <div className="scrollable-box" ref={askOrdersRef}>
              <ul className="list-unstyled">
                {asksArray.length > 0 ? (
                  asksArray.map((order, index) => (
                    <li
                      key={index}
                      className="d-flex justify-content-between text-danger"
                    >
                      <span
                        onClick={() =>
                          setQuantityPriceValue({
                            quantity: parseFloat(order[1]).toFixed(2),
                            price: parseFloat(order[0]).toFixed(2),
                          })
                        }
                      >
                        {parseFloat(order[0]).toFixed(2)}
                      </span>
                      <span
                        onClick={() =>
                          setQuantityPriceValue({
                            quantity: parseFloat(order[1]).toFixed(2),
                            price: parseFloat(order[0]).toFixed(2),
                          })
                        }
                      >
                        {parseFloat(order[1]).toFixed(2)}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>No ask orders available</li>
                )}
              </ul>
            </div>
          </div>

          <div className="bid-orders mt-3">
            <h8>Bid Orders</h8>
            <div className="scrollable-box">
              <ul className="list-unstyled">
                {bidsArray.length > 0 ? (
                  bidsArray.map((order, index) => (
                    <li
                      key={index}
                      className="d-flex justify-content-between text-success"
                    >
                      <span
                        onClick={() =>
                          setQuantityPriceValue({
                            quantity: parseFloat(order[1]).toFixed(2),
                            price: parseFloat(order[0]).toFixed(2),
                          })
                        }
                      >
                        {parseFloat(order[0]).toFixed(2)}
                      </span>
                      <span
                        onClick={() =>
                          setQuantityPriceValue({
                            quantity: parseFloat(order[1]).toFixed(2),
                            price: parseFloat(order[0]).toFixed(2),
                          })
                        }
                      >
                        {parseFloat(order[1]).toFixed(2)}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>No bid orders available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orderbook;
