import React from "react";
import "./wallet.css";

function OrderTable({ orders, orderType }) {
  return (
    <div className="coin-history-container">
      <h4 className="heading">{orderType} Orders</h4>
      <div className="coin-history-cards">
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order._id} className="card">
              <div className="card-row">
                <div><strong>Order ID:</strong> {order.order_id}</div>
              </div>
              <div className="card-row">
              <div><strong>Currency:</strong> {order.symbol}</div>
                <div><strong>Status:</strong> {order.order_status === 1 ? "Executed" : "Open"}</div>
                <div><strong>Price:</strong> {order.raw_price}</div>
              </div>
              <div className="card-row">
                <div><strong>Volume:</strong> {order.volume+order.total_executed}</div>
                <div><strong>Executed:</strong> {order.total_executed}</div>
              </div>
              <div className="card-row">
                <div><strong>Time:</strong> {new Date(order.updatedAt).toLocaleString()}</div>
              </div>
            </div>
          ))
        ) : (
          <div>No {orderType.toLowerCase()} orders available</div>
        )}
      </div>
    </div>
  );
}

export default OrderTable;
