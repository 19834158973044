// api.js
import axios from "axios";

const API_URL = "https://api.myrubywallet.com/api/";
const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

export const fetchTradingPairs = async () => {
  try {
    const response = await axios.get(API_URL + 'getpair');
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching trading pairs:", error);
    throw error; // Re-throw the error for handling in the component
  }
};



export const registerUser = async (userData) => {
  try {
    const response = await axios.post(API_URL + 'registerUser', userData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

export const verifyOTP = async (userData, token) => {
  try {
    const response = await axios.post(
      API_URL + 'verifyUserEmail',
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("OTP verification error:", error);
    throw error;
  }
};


export const loginUser = async (userData) => {
  try {
    const response = await axios.post(API_URL + 'loginUser', userData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};



export const getwallet = async () => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'getwallet',
      {},  // Make sure to send an empty object or required body data
      {
        headers: {
          Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Get wallet error:", error);
    throw error;
  }
};


export const getuserorder = async () => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'getuserorder',
      {},  // Make sure to send an empty object or required body data
      {
        headers: {
          Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Get wallet error:", error);
    throw error;
  }
};



export const gettransaction = async () => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'gettransaction',
      {},  // Make sure to send an empty object or required body data
      {
        headers: {
          Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Get wallet error:", error);
    throw error;
  }
};


export const sellOrder = async (symbol, raw_price, volume) => {
  try {
    const response = await axios.post(API_URL + 'sell', {
      symbol,
      raw_price,
      volume,
    }, // Corrected: Add a comma after the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      });
    return response.data; // Return the response data if needed
  } catch (error) {
    console.error('Error selling order:', error);
    throw error; // Rethrow the error for handling in the component
  }
};


export const buyOrder = async (symbol, raw_price, volume) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'buy', // API endpoint
      {
        symbol,
        raw_price,
        volume,
      }, // Corrected: Add a comma after the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data; // Return the response data if needed
  } catch (error) {
    console.error('Error placing buy order:', error);
    throw error; // Rethrow the error for handling in the component
  }
};


export const setPersonalInfo = async (formData) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'setPersonalInfo',
      formData,  // Send the form data in the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};
export const getInfo = async () => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'getInfo', {},
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};
export const getmarket = async () => {

  try {
    const response = await axios.get(API_URL + 'getmarket');
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const verifiePanCard = async (pan_no) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'verifiePanCard', { pan_no },
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const sendAadharOtp = async (id_number) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'sendAadharOtp', { id_number },
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};
export const verifieAadharcard = async (aadharno, otp, client_id) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'verifieAadharcard', { aadharno, otp, client_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const createwallets = async (symbol) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const payload = { symbol: symbol }; // or simply { symbol } since it uses the same key

    const response = await axios.post(
      API_URL + 'createwallets', payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const getbalance = async (symbol) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const payload = { symbol: symbol }; // or simply { symbol } since it uses the same key

    const response = await axios.post(
      API_URL + 'getbalance', payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};


export const getorder = async (symbol) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const payload = { symbol: symbol }; // or simply { symbol } since it uses the same key

    const response = await axios.post(
      API_URL + 'getorder', {},
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};



export const withdraw = async (symbol, amount, address) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const payload = { symbol: symbol, amount: amount, address: address }; // or simply { symbol } since it uses the same key

    const response = await axios.post(
      API_URL + 'withdraw', payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};



export const cancelorder = async (orderId, ordertype) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const payload = { orderId: orderId, ordertype: ordertype }; // or simply { symbol } since it uses the same key

    const response = await axios.post(
      API_URL + 'cancelorder', payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

// utils/tokenUtils.js
export const isTokenValid = () => {
  if (!token) return false;

  try {
    const { exp } = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    return exp * 1000 > Date.now(); // Check if the token is expired
  } catch (error) {
    return false; // If decoding fails, consider the token invalid
  }
};


export const sendotp = async () => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'sendotp',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("OTP verification error:", error);
    throw error;
  }
};



export const changepassword = async (data) => {
  try {
    console.log("Payload being sent:", data);

    const response = await axios.post(
      `${API_URL}changepassword`,
      data, // Send the payload directly here
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};