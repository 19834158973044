import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sale01 from '../components/sale/Sale01';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import img from '../assets/images/icon/qrcode.png';
import { loginUser } from '../api';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailLogin = async (e) => {
        e.preventDefault();
        const userData = { email, password };
        try {
            const response = await loginUser(userData);
            
            if (response.error) {
                console.log("response",response.message)
           toast.error(response.message);

            } else {
                // Success toast
                // toast.success(response.message || 'Login successful!', {
                //     position: toast.POSITION.TOP_CENTER,
                //     autoClose: 3000,
                // });
                toast.success(response.message || 'Login successful!');

                // Store token in localStorage
                const token = response?.params?.token;
                if (token) {
                    localStorage.setItem('authToken', token);
                }
                navigate('/wallet'); // Redirect on success
            }
        } catch (error) {
            // console.error('Login failed:', error);
            // toast.error('An unexpected error occurred. Please try again.', {
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000,
            // });
           toast.error("ReTry");

        }
    };

    return (
        <div>
            <PageTitle heading="Login" title="Login" />

            <section className="register login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">Login To Rockie</h3>
                                <p className="desc fs-20">
                                    Welcome back! Log In now to start trading
                                </p>
                                <div className="lock">
                                    <div className="icon">
                                        {/* Your SVG Icon */}
                                    </div>
                                    <p><span>https://</span>accounts.rockie.com/login</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Tabs>
                                <TabPanel>
                                    <div className="content-inner">
                                        <form onSubmit={handleEmailLogin}>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email/ID</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    placeholder="Please fill in the email form."
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group s1">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Please enter a password."
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group form-check">
                                                <div>
                                                    <input type="checkbox" className="form-check-input" />
                                                    <label className="form-check-label">Remember Me</label>
                                                </div>
                                                {/* <p>Forgot Password?</p> */}
                                            </div>

                                            <button type="submit" className="btn-action">Login</button>
                                            <div className="bottom">
                                                <p>Not a member?</p>
                                                <Link to="/register">Register</Link>
                                            </div>
                                        </form>
                                    </div>
                                </TabPanel>
                            </Tabs>

                            <div className="qr-code center">
                                <img src={img} alt="" />
                                <h6 className="fs-20">Login with QR code</h6>
                                <p className="fs-14">
                                    Scan this code with the <span>Rockie mobile app</span> <br />
                                    to log in instantly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sale01 />
            <ToastContainer /> {/* ToastContainer to display toasts */}
        </div>
    );
}

export default Login;
