import React, { useState } from "react";
import Tradedata24h from "../components/tradedata24h/tradedata24h";
import MyTradingView from "../components/myTrades/MyTradingView";
import TradingViewChart from "../components/myTrades/TradingViewChart";
import Buy from "../components/limitMarketView/buy";
import Sell from "../components/limitMarketView/sell";
import ActiveOrders from "../components/activeOrders/ActiveOrders";

const TradeContainer = () => {
  const [selectedAction, setSelectedAction] = useState("buy"); // Initialize with 'buy'
  const [quanityPriceValue, setQuanityPriceValue] = useState({
    quantity: 0,
    price: 0,
  }); // Initialize with 'buy'

  // Handler for Buy button
  const handleBuyClick = () => {
    setSelectedAction("buy"); // Set action to buy
  };

  // Handler for Sell button
  const handleSellClick = () => {
    setSelectedAction("sell"); // Set action to sell
  };

  const clickedSellBuyValue = (value) => {
    setQuanityPriceValue({
      price: value.price,
      quantity: value.quantity,
    });
  };

  return (
    <main className="show">
      <div className="container-xxl">
        <div className="row">
          <div className="col-md-12">
            <Tradedata24h />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-md-6">
            <div className="charts">
              <TradingViewChart /> {/* Default to first pair */}
            </div>
          </div>

          {/* Buy and Sell Buttons */}
          <div className="col-sm-4 col-md-3">
            <div style={buttonContainerStyle}>
              <button
                onClick={handleBuyClick}
                style={{
                  ...buyButtonStyle,
                  ...(selectedAction === "buy" ? highlightButtonStyle : {}),
                }}
              >
                Buy
              </button>
              <button
                onClick={handleSellClick}
                style={{
                  ...sellButtonStyle,
                  ...(selectedAction === "sell" ? highlightButtonStyle : {}),
                }}
              >
                Sell
              </button>
            </div>

            {/* Conditional Rendering */}
            <div className="mt-3">
              {selectedAction === "buy" && (
                <Buy quanityPriceValue={quanityPriceValue} />
              )}
              {selectedAction === "sell" && (
                <Sell quanityPriceValue={quanityPriceValue} />
              )}
            </div>
          </div>

          <div className="col-sm-4 col-md-3 justify-content-end">
            <MyTradingView clickedSellBuyValue={clickedSellBuyValue} />{" "}
            {/* Default to first pair */}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <ActiveOrders />
          </div>
        </div>
      </div>
    </main>
  );
};

// Button styles
const buyButtonStyle = {
  backgroundColor: "rgb(109, 135, 109)", // Green background for Buy button
  color: "white",
  border: "none",
  padding: "2px 0",
  borderRadius: "5px",
  cursor: "pointer",
  flex: 1, // Make the button take equal space
  margin: "5px", // Space between buttons
};

const sellButtonStyle = {
  backgroundColor: "rgb(161, 134, 134)", // Red background for Sell button
  color: "white",
  border: "none",
  padding: "2px 0",
  borderRadius: "5px",
  cursor: "pointer",
  flex: 1, // Make the button take equal space
  margin: "5px", // Space between buttons
};

// Highlight style for selected button
const highlightButtonStyle = {
  boxShadow: "0 0 10px rgba(255, 255, 255, 0.6)", // Add glow effect
  border: "1px solid white", // Optional: a border for better visibility
  backgroundColor: "#ad793e",
};

// Button container style
const buttonContainerStyle = {
  display: "flex", // Use flexbox
  width: "100%", // Full width
  height: "2.5rem",
};

export default TradeContainer;
