import React, { useState } from "react";
import Sale01 from "../components/sale/Sale01";
import Kyc from "../components/kyc/kyc";
import GetuserInfo from "../components/kyc/userinfo";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import { sendotp, changepassword } from "../api"; // Adjust the path according to your file structure
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useNavigate } from 'react-router-dom';

function UserProfile(props) {
  const navigate = useNavigate();

  const [dataCoinTab] = useState([
    {
      id: 1,
      title: "User Profile",
      icon: "fa-user",
    },
    {
      id: 6,
      title: "KYC",
      icon: "fa-user",
    },
    {
      id: 5,
      title: "Change Password",
      icon: "fa-lock",
    },
  ]);

  // State management
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false); // Manage OTP input visibility

  // Function to handle OTP sending
  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const response = await sendotp();
      if (response.status) {
        // alert("OTP sent successfully. Please check your email.");
        toast.success(response.message || 'OTP sent successfully. Please check your email.');

        setIsOtpSent(true); // Show OTP input box
      } else {
        // alert(response.message || "Failed to send OTP.");
        toast.error("Failed to send OTP.");

      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP. Please try again.");

    } finally {
      setLoading(false);
    }
  };

  // Function to handle password change
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");

      return;
    }

    if (!otp) {
      toast.error("Please enter the OTP.");

      return;
    }

    setLoading(true);
    try {
      console.log("newpassword",newPassword,otp)
      let data =
      { newpassword: newPassword,otp: otp }
      const response = await changepassword(data);
      if (response.status) {
        toast.success("Password changed successfully! Please check your email.")

        setIsOtpSent(false); // Reset OTP input box visibility
        navigate('/wallet'); // Redirect on success

      } else {
        toast.error("Failed to change password.");

      }
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error("Error changing password. Please try again.");

    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <style>
        {`
          .change-pass {
            max-width: 400px;
            margin: 0 auto;
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .change-pass h4 {
            text-align: center;
            color: #333;
            font-weight: bold;
          }
          .change-password-form .form-group {
            margin-bottom: 20px;
          }
          .change-password-form label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
          }
          .change-password-form input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .change-password-form .btn {
            width: 100%;
            padding: 10px;
            margin-top: 10px;
            font-weight: bold;
            border: none;
            border-radius: 4px;
          }
          .btn-primary {
            background-color: #007bff;
            color: #fff;
          }
          .btn-success {
            background-color: #28a745;
            color: #fff;
          }
          .btn:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        `}
      </style>

      <PageTitle heading="User Profile" title="User" />

      <section className="user-profile flat-tabs">
        <div className="container">
          <div className="row">
            <Tabs>
              <TabList>
                {dataCoinTab.map((tab) => (
                  <Tab key={tab.id}>
                    <h6 className="fs-16">
                      <i className={`fa ${tab.icon}`}></i>
                      {tab.title}
                    </h6>
                  </Tab>
                ))}
              </TabList>

              <TabPanel>
                <GetuserInfo />
              </TabPanel>
              <TabPanel>
                <Kyc />
              </TabPanel>

              <TabPanel>
                <div className="content-inner profile change-pass">
                  <h4>Change Password</h4>
                  <form className="change-password-form">
                    <div className="form-group">
                      <label>New Password<span>*</span></label>
                      <input
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password<span>*</span></label>
                      <input
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    {isOtpSent && (
                      <div className="form-group">
                        <label>OTP<span>*</span></label>
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    )}
                    {!isOtpSent && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSendOtp}
                        disabled={loading}
                      >
                        {loading ? "Sending OTP..." : "Send OTP"}
                      </button>
                    )}
                    {isOtpSent && (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleChangePassword}
                        disabled={loading}
                      >
                        {loading ? "Changing Password..." : "Change Password"}
                      </button>
                    )}
                  </form>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>

      <Sale01 />
      <ToastContainer /> {/* ToastContainer to display toasts */}

    </div>
  );
}

export default UserProfile;
