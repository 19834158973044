import React, { useState, useEffect } from "react";
import { setPersonalInfo, getInfo } from "../../api"; // Import the API functions
import PanCardVerification from "./PanCardVerification"; // Import PanCardVerification component
import "./kyc.css"; // Import the CSS file

function Key() {
  const [userDetails, setUserDetails] = useState(null); // State to hold user details
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error messages
  const [responseMessage, setResponseMessage] = useState(""); // Response message after form submission
  const [showPANBox, setShowPANBox] = useState(false); // Track if PAN card box should be shown

  // Fetch user information
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getInfo(); // Call the getInfo API function
        if (response.status === 200) {
          if (response.error) {
            setErrorMessage("Please verify your KYC.");
          } else {
            setUserDetails(response); // Set the user details
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        setErrorMessage("An error occurred while fetching user information.");
      }
    };

    fetchUserInfo(); // Fetch user info on component mount
  }, []);

  const [formData, setFormData] = useState({
    kyc_type: "level 1",  // Default value
    first_name: "",
    date_of_birth: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    email: "",
    mobile_no: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await setPersonalInfo(formData); // Pass the form data to the API

      if (response.status === 200 && !response.error) {
        setResponseMessage(response.message); // Set response message

        if (response.query_status === 1) {
          setResponseMessage("Already applied.");
        } else {
          setResponseMessage("Submitted successfully! Please enter your PAN card number.");
          setShowPANBox(true); // Show the PAN card box
        }
      } else {
        setResponseMessage("Error occurred while submitting the form.");
      }
    } catch (error) {
      setResponseMessage("Error occurred while submitting the form.");
      console.error("Error updating profile:", error);
    }
  };

  // Handle PAN card number submission from the PanCardVerification component
  const handlePanSubmit = (panCardNumber) => {
    // Handle further submission logic for PAN card here, e.g., sending it to your API
  };

  // Conditionally render the form or KYC verified message
  if (userDetails && userDetails.checkkyc === 1) {
    return <div className="content-inner profile"><h4>KYC already verified</h4></div>;
  }

  return (
    <div className="content-inner profile">
      <form onSubmit={handleSubmit}>
        <h4>User Profile</h4>
        <h6>Information</h6>

        <div className="form-group d-flex">
          <input
            type="text"
            className="form-control"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
          />
          <input
            type="text"
            className="form-control"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            placeholder="Date of Birth (DD/MM/YYYY)"
          />
        </div>

        <div className="form-group d-flex">
          <input
            type="text"
            className="form-control"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Address"
          />
          <input
            type="text"
            className="form-control"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
          />
        </div>

        <div className="form-group d-flex">
          <input
            type="text"
            className="form-control"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="State"
          />
          <input
            type="text"
            className="form-control"
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
            placeholder="Pincode"
          />
        </div>

        <div className="form-group d-flex">
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />
          <input
            type="text"
            className="form-control"
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleChange}
            placeholder="Mobile Number"
          />
        </div>

        <div className="form-group">
          <select
            className="form-control"
            name="country"
            value={formData.country}
            onChange={handleChange}
          >
            <option value="">Select Country</option>
            <option value="India">India</option>
            {/* Add other country options */}
          </select>
        </div>

        <div className="form-group">
          <select
            className="form-control"
            name="kyc_type"
            value={formData.kyc_type}
            onChange={handleChange}
          >
            <option value="level 1">Level 1</option>
            <option value="level 2">Level 2</option>
          </select>
        </div>

        <button type="submit" className="btn-action">
          Update Profile
        </button>
      </form>

      {responseMessage && (
        <div className={`response-message ${responseMessage.includes('successfully') ? 'success' : 'error'}`}>
          {responseMessage}
        </div>
      )}

      {showPANBox && (
        <PanCardVerification onPanSubmit={handlePanSubmit} />
      )}
    </div>
  );
}

export default Key;
