const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
           
    },

    {
        id: 3,
        name: 'Markets ',
        links: '/markets'
    },
    {
        id: 6,
        name: 'Spot',
        links: '/spot/RUBY-INR'
    },
   
   
    
    
    {
        id: 7,
        name: 'Account',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'User Profile',
                links: '/user-profile'
            },
            {
                id: 2,
                sub: 'Login',
                links: '/login'
            },
            {
                id: 3,
                sub: 'Register',
                links: '/register'
            },
            {
                id: 4,
                sub: 'Contact',
                links: '/contact'
            },
            {
                id: 5,
                sub: 'FAQ',
                links: '/faq'
            },
        ],
    },
    
]

export default menus;