import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  subscribeToTradeHistory,
  unsubscribeFromOrderBook,
} from "../../socketService"; // Import your socket service
import { useParams } from 'react-router-dom';

const TradingViewChart = () => {
  const { market } = useParams();

  const [chartType, setChartType] = useState("Area"); // Default to Area chart
  const [timeInterval, setTimeInterval] = useState("30D"); // Default to 30 Days
  const [tradeHistory, setTradeHistory] = useState([]);

  // Fetch trade history when the component mounts or when the selected pair changes
  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === market && data.tradeHistory) {
        setTradeHistory(data.tradeHistory);
      } else {
        setTradeHistory([]); // Reset the trade history if no valid data
      }
    };

    // Subscribe to trade history updates for the selected pair
    subscribeToTradeHistory(market, handleTradeHistoryUpdate);

    // Cleanup function: Unsubscribe from trade history when the component unmounts or pair changes
    return () => {
      unsubscribeFromOrderBook(market);
    };
  }, [market]);

  // Process trade history data for recharts
  const filteredData = tradeHistory.map((trade) => ({
    name: trade.createdAt, // Assuming 'createdAt' is the timestamp field
    price: trade.price, // Assuming 'price' is the trade price field
  }));

  // Format large Y-axis values (e.g., 1000 -> 1k, 1000000 -> 1M)
  const formatYAxis = (value) => {
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
    return value;
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "10px",
        borderRadius: "8px",
      }}
    >
      {/* Dropdowns for selecting chart type and time interval */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <select
            id="chartTypeSelect"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            className="form-select"
            style={{ marginLeft: "5px" }}
          >
            <option value="Line">Line Chart</option>
            <option value="Area">Area Chart</option>
            <option value="Bar">Bar Chart</option>
          </select>
        </div>
        <div>
          <select
            id="timeIntervalSelect"
            value={timeInterval}
            onChange={(e) => setTimeInterval(e.target.value)}
            className="form-select"
            style={{ marginLeft: "5px" }}
          >
            <option value="30D">30 Days</option>
            <option value="1H">1 Hour</option>
            <option value="30M">30 Minutes</option>
            <option value="15M">15 Minutes</option>
          </select>
        </div>
      </div>

      {/* Render the chart based on selected chart type */}
      <ResponsiveContainer width="100%" height={300}>
        {chartType === "Line" ? (
          <LineChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxis} domain={["auto", "auto"]} />
            <Tooltip formatter={(value) => [`Price: ${value}`, ""]} />
            <Legend />
            <Line
              type="monotone"
              dataKey="price"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        ) : chartType === "Area" ? (
          <AreaChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <Tooltip formatter={(value) => [`Price: ${value}`, ""]} />
            <Legend />
            <Area
              type="monotone"
              dataKey="price"
              stroke="#8884d8"
              fillOpacity={13}
              // fill="url(#colorPrice)"
            />
            <defs>
              <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={20} />
              </linearGradient>
            </defs>
          </AreaChart>
        ) : (
          <BarChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxis} domain={["auto", "auto"]} />
            <Tooltip formatter={(value) => [`Price: ${value}`, ""]} />
            <Legend />
            <Bar dataKey="price" fill="#8884d8" />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default TradingViewChart;
