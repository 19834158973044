import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "./wallet.css";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createwallets, withdraw } from "../../api"; // Adjust the path according to your file structure

function Overview({ totalBalance, walletData }) {
  const [expandedRow, setExpandedRow] = useState(null);
  const [withdrawRow, setWithdrawRow] = useState(null);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [hideBalance, setHideBalance] = useState(false);

  const handleDepositClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleWithdrawClick = (index) => {
    setWithdrawRow(withdrawRow === index ? null : index);
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Wallet address copied to clipboard!");
  };

  const toggleHideBalance = () => {
    setHideBalance(!hideBalance);
  };

  const handleCreateWallet = async (symbol) => {
    try {
    let wallet=   await createwallets(symbol);
    console.log("wallet",wallet)
    if(wallet.error){
      toast.error(wallet.message);
      window.location.reload();
    }else if(wallet.error===false){
      toast.success(wallet.message);

    }
    } catch (error) {
      console.error("Error creating wallet:", error);
      toast.error("Error creating wallet. Please try again.");
    }
  };

  const handleWithdraw = async (symbol) => {
    try {
    let res=  await withdraw(symbol, amount, address);
    console.log("res",res)
    if(res.success){
      toast.success(res.message);
      window.location.reload();
    }else{
      toast.error(res.message);

    }
      setWithdrawRow(null);
    } catch (error) {
      console.error("Error during withdrawal:", error);
      toast.error("Withdrawal failed. Please try again.");
    }
  };

  return (
    <div className="content-inner">
      <ToastContainer />
      <div className="wallet-main">
        <h4 className="heading">Overview</h4>
        <div className="wallet-body">
          <div className="left">
            <p>Total Balance</p>
            <div className="price">
              <h6>{totalBalance}</h6>
              <div className="sale success">INR</div>
            </div>
          </div>
          <div className="right">
            <button onClick={toggleHideBalance} className="btn-action">
              {hideBalance ? "Show all balances" : "Hide zero balances"}
            </button>
          </div>
        </div>
      </div>

      <div className="coin-list-wallet">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th className="center" scope="col">
                Asset
              </th>
              <th scope="col">On Orders</th>
              <th scope="col">Available balance</th>
              <th scope="col">Total balance</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
  {walletData.length > 0 ? (
    walletData
      .filter((wallet) => !hideBalance || wallet.balance > 0)
      .map((wallet, index) => (
        <React.Fragment key={wallet._id}>
          <tr>
            <td className="number">
              <span>{index + 1}</span>
            </td>
            <td className="asset">
              <img
                src={wallet.currencyDetails.icon}
                alt={wallet.currencyDetails.name}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
              />
              <p>
                <span className="boild">
                  {wallet.currencyDetails.symbol}
                </span>
                <span className="unit">
                  {wallet.currencyDetails.name}
                </span>
              </p>
            </td>
            <td>
              <span className="boild">
                {wallet.locked || 0} {wallet.currencyDetails.symbol}
              </span>
              <span className="unit">
                ₹{wallet.lockbalanceInINR.toLocaleString()}
              </span>
            </td>
            <td>
              <span className="boild">
                {wallet.balance || 0} {wallet.currencyDetails.symbol}
              </span>
              <span className="unit">
                ₹{wallet.balance_in_inr.toLocaleString()}
              </span>
            </td>
            <td>
              <span className="boild">
                {wallet.balance + wallet.locked || 0}{" "}
                {wallet.currencyDetails.symbol}
              </span>
              <span className="unit">
                ₹{wallet.balance_in_inr + wallet.lockbalanceInINR}
              </span>
            </td>
            <td className="color-success">
              {wallet.wallet_address ? (
                <div style={{ display: "flex", gap: "10px" }}>
                  {wallet.currencyDetails.is_deposite === 1 && (
                    <button
                      onClick={() => handleDepositClick(index)}
                      className="btn btn-success"
                      style={{ fontSize: "10px" }}
                    >
                      Deposit
                    </button>
                  )}

                  {wallet.currencyDetails.is_withdrawal === 1 && (
                    <button
                      onClick={() => handleWithdrawClick(index)}
                      className="btn btn-danger"
                      style={{ fontSize: "10px" }}
                    >
                      Withdrawal
                    </button>
                  )}

                  {wallet.currencyDetails.is_trade === 1 && (
                    <Link to={`/spot/${wallet.currencyDetails.symbol}-INR`}>
                    <button
                        className="btn btn-danger"
                        style={{ fontSize: "10px" }}
                      >
                        Trade
                      </button>
                    </Link>
                  )}
                </div>
              ) : (
                <button
                  onClick={() =>
                    handleCreateWallet(wallet.currencyDetails.symbol)
                  }
                  className="btn btn-warning"
                  style={{ fontSize: "10px" }}
                >
                  Create
                </button>
              )}
            </td>
          </tr>

          {expandedRow === index && (
            <tr>
              <td colSpan="6">
                <div
                  className="wallet-deposit-info"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {wallet.currencyDetails.symbol === "INR" ? (
                    <button className="btn btn-primary">Confirm</button>
                  ) : (
                    <>
                      <div>
                        <p className="wallet_address">
                          <strong>Wallet Address:</strong>{" "}
                        </p>
                        <p className="wallet_address">
                          {wallet.wallet_address}
                          <button
                            onClick={() =>
                              handleCopyAddress(wallet.wallet_address)
                            }
                            className="btn btn-sm btn-info"
                            style={{
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                          >
                            Copy
                          </button>
                        </p>
                        <p className="wallet_address">
                          <strong>Disclaimer:</strong>
                          <div>
                            Please deposit only{" "}
                            {wallet.currencyDetails.symbol} to this address. If
                            you deposit any other coins or below the minimum
                            limit, it will be lost forever.
                          </div>
                        </p>
                      </div>
                      <QRCodeCanvas value={wallet.wallet_address} size={128} />
                    </>
                  )}
                </div>
              </td>
            </tr>
          )}

          {withdrawRow === index && (
            <tr>
              <td colSpan="6">
                <div className="withdraw-form">
                  <h6>Withdrawal Form</h6>
                  <div className="withdraw-field">
                    <label>Withdrawal Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Enter wallet address"
                    />
                  </div>
                  <div className="withdraw-field">
                    <label>Amount</label>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="Enter amount"
                    />
                  </div>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      handleWithdraw(wallet.currencyDetails.symbol)
                    }
                  >
                    Submit Withdrawal
                  </button>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))
  ) : (
    <tr>
      <td colSpan="6">No wallet data available</td>
    </tr>
  )}
</tbody>

        </table>
      </div>
    </div>
  );
}

export default Overview;
