import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';  // Importing the menu data
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo-dark.png';
import DarkMode from './DarkMode';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    const [menuActive, setMenuActive] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false); // For auth token

    // Handle scroll event
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        };
    }, []);

    // Check token on component mount
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        setIsAuthenticated(!!token); // Set auth status based on token presence
    }, []);

    // Re-check token when component is re-rendered or login/logout happens
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        setIsAuthenticated(!!token);
    }, [localStorage.getItem('authToken')]);  // This makes sure it listens for token changes

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const handleDropdown = (index) => {
        setActiveIndex(index);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Clear token on logout
        setIsAuthenticated(false); // Update auth status
        window.location.reload(); // Optionally reload page to ensure state updates
    };

    // Conditionally update the menu
    const updatedMenus = menus.map(menu => {
        if (menu.name === 'Account') {
            return {
                ...menu,
                namesub: isAuthenticated
                    ? [  // Show logout option if authenticated
                        {
                            id: 1,
                            sub: 'User Profile',
                            links: '/user-profile'
                        },
                        {
                            id: 2,
                            sub: 'Logout',
                            links: '#',
                            onClick: handleLogout
                        }
                    ]
                    : [  // Show login/register if not authenticated
                        {
                            id: 1,
                            sub: 'User Profile',
                            links: '/user-profile'
                        },
                        {
                            id: 2,
                            sub: 'Login',
                            links: '/login'
                        },
                        {
                            id: 3,
                            sub: 'Register',
                            links: '/register'
                        },
                        {
                            id: 4,
                            sub: 'Contact',
                            links: '/contact'
                        },
                        {
                            id: 5,
                            sub: 'FAQ',
                            links: '/faq'
                        },
                    ]
            };
        }
        return menu;
    });

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body d-flex justify-content-between">
                            <div className="header__left">
                                <div className="logo">
                                    <NavLink to='/' className="light">
                                        <img src={logo} alt="Rockie" />
                                    </NavLink>
                                    <NavLink to='/' className="dark">
                                        <img src={logodark} alt="Rockie" />
                                    </NavLink>
                                </div>
                                <div className="left__main">
                                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                        <ul id="menu-primary-menu" className="menu">
                                            {updatedMenus.map((data, idx) => (
                                                <li
                                                    key={idx}
                                                    onClick={() => handleDropdown(idx)}
                                                    className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}
                                                >
                                                    <Link to={data.links}>{data.name}</Link>
                                                    {data.namesub && (
                                                        <ul className="sub-menu">
                                                            {data.namesub.map((submenu) => (
                                                                <li key={submenu.id} className="menu-item">
                                                                    <NavLink
                                                                        to={submenu.links}
                                                                        onClick={submenu.onClick ? submenu.onClick : null}  // Handle logout click
                                                                    >
                                                                        {submenu.sub}
                                                                    </NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className="header__right">
                                <DarkMode />
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}>
                                    <span></span>
                                </div>
                                <div className="wallet">
                                    <Link to="/wallet"> Wallet </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
