

import img1 from '../images/avt/avt-02.png';
import img2 from '../images/avt/avt-03.png';
import img3 from '../images/avt/avt-04.png';
import logo from '../images/partner/logo-05.png';



const dataTestimonial = [
    {
        id: 1,
        text: " “Great course I really enjoyed it and the course was way easy to learn with very good explanations of the code, I could easily understand and develop applications with the knowledge gathered during the course.” ",
        avt: img1,
        name: 'Johnny Andro',
        position: 'Director, Company',
        partner: logo
    },
    {
        id: 2,
        text: " “Great course I really enjoyed it and the course was way easy to learn with very good explanations of the code, I could easily understand and develop applications with the knowledge gathered during the course.” ",
        avt: img2,
        name: 'Johnny Andro',
        position: 'Director, Company',
        partner: logo
    },
    {
        id: 3,
        text: " “Great course I really enjoyed it and the course was way easy to learn with very good explanations of the code, I could easily understand and develop applications with the knowledge gathered during the course.” ",
        avt: img3,
        name: 'Johnny Andro',
        position: 'Director, Company',
        partner: logo
    },
   
]

export default dataTestimonial;