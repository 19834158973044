import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import img1 from '../../assets/images/coin/btc.png';
import img2 from '../../assets/images/coin/eth.png';
import img3 from '../../assets/images/coin/tet.png';
import img4 from '../../assets/images/coin/bnb.png';

import './styles.scss';
import { getmarket } from "../../api"; // Import the API function
import { Link } from 'react-router-dom';

Crypto01.propTypes = {};

function Crypto01(props) {
    const [dataCrytoContents, setdataCrytoContent] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [dataCrytoTab] = useState([
        { id: 1, title: 'Crypto' },
       
    ]);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const response = await getmarket();
                if (response.success === true) {
                    if (response.error) {
                        setErrorMessage("Error: " + response.error);
                    } else {
                        setdataCrytoContent(response.data);
                    }
                } else {
                    setErrorMessage("Error: Unable to fetch data.");
                }
            } catch (error) {
                console.error("Error fetching market data:", error);
                setErrorMessage("An error occurred while fetching market data.");
            }
        };

        fetchMarketData();
    }, []);

    if (errorMessage) {
        return <h4>{errorMessage}</h4>;
    }

    return (
        <section className="crypto" data-aos="fade-up" data-aos-duration="1000">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="crypto__main">
                            <Tabs>
                                <TabList>
                                    {dataCrytoTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))}
                                </TabList>

                                {dataCrytoTab.map(data => (
                                    <TabPanel key={data.id}>
                                        <div className="content-inner">
                                            {dataCrytoContents.slice(0, 4).map(data => (
                                                <div key={data.id} className={`crypto-box ${data.is_paired_inr}`}>
                                                    <div className="top">
                                                        <Link to="#">
                                                            <img src={data.icon} alt="rockie" />
                                                            <span>{data.symbol}</span>
                                                            <span className="unit">{data.market}</span>
                                                        </Link>
                                                    </div>
                                                    <h6 className="price">{data.inr_price}</h6>
                                                    <div className="bottom">
                                                        <p>{data.inr_price}</p>
                                                        <p className={`sale success`}>{data.usdt_price}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Crypto01;
