import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sale01 from '../components/sale/Sale01';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import { registerUser, verifyOTP } from '../api';  // Import the registerUser and verifyOTP functions
import "./Register.css";
import { useNavigate } from 'react-router-dom';  // Import useNavigate

Register.propTypes = {};

function Register(props) {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
    });

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();  // Initialize useNavigate for redirection

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Basic validation
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
    
        try {
            let data = {
                email: formData.email,
                password: formData.password
            };
            const response = await registerUser(data);
            if (!response.error) {
                // Check if a token exists in localStorage, and delete it before storing the new one
                // Store the new token and show the OTP popup
                alert(response.message);
                localStorage.setItem('userToken', response.params.token);
                setIsPopupVisible(true); // Show the popup for OTP
            } else {
                alert("Registration failed. Please try again.");
            }
        } catch (error) {
            alert("Registration failed. Please try again.");
        }
    };

    const handleOTPChange = (e) => {
        setOtp(e.target.value);
    };

    const handleOTPSubmit = async () => {
        try {
            const token = localStorage.getItem('userToken');  // Fetch the token from localStorage
            if (!token) {
                alert('No token found, please try registering again.');
                return;
            }
            // Call verifyOTP with OTP and token
            const response = await verifyOTP({ otp }, token);  // Pass OTP as userData, token as separate argument
            if (response.status === 200 && !response.error) {
                alert(response.message);
                // Store new token if provided in response
                if (response.params && response.params.token) {
                    console.log("response", response.params.token);
                    localStorage.setItem('authToken', response.params.token);
                }
                // Hide the OTP popup and redirect or continue further
                setIsPopupVisible(false);
                navigate('/login');  // Redirect to the login page
                // Example: Redirect the user to a dashboard or login page
                // window.location.href = '/dashboard';
            } else {
                alert('OTP verification failed. Please try again.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            alert('Failed to verify OTP. Please try again.');
        }
    };
    
    

    return (
        <div>
            <PageTitle heading='Register' title='Register' />
            <section className="register">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">Register To Rockie</h3>
                                <p className="desc fs-20">
                                    Register in advance and enjoy the event benefits
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Tabs>   
                                <TabPanel>
                                    <div className="content-inner">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email">Email/ID</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Please fill in the email form."
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Password
                                                    <span>(8 or more characters, including numbers and special characters)</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control mb-10"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    placeholder="Please enter a password."
                                                    required
                                                />
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                    placeholder="Please re-enter your password."
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Name
                                                    <span className="fs-14">(Excluding special characters)</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Enter your name"
                                                    required
                                                />
                                            </div>
                                            <button type="submit" className="btn-action">
                                                Registration
                                            </button>
                                            <div className="bottom">
                                                <p>Already have an account?</p>
                                                <Link to="/login">Login</Link>
                                            </div>
                                        </form>
                                    </div>
                                </TabPanel>
                            </Tabs> 
                        </div>
                    </div>
                </div>
            </section>

            {/* Popup Box */}
            {isPopupVisible && (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Email Verification</h3>
                        <p>Please enter the OTP sent to your email</p>
                        <input
                            type="text"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter OTP"
                        />
                        <button onClick={handleOTPSubmit}>Verify OTP</button>
                    </div>
                </div>
            )}

            <Sale01 />
        </div>
    );
}

export default Register;
