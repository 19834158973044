import React, { useEffect, useState } from "react";
import OrderBook from "../myTrades/OrderBook";
import MarketTrade from "../myTrades/MarketTrade";
import "./orderbook.css";

const MyTradingView = ({ selectedPair, clickedSellBuyValue }) => {
  const [activeTab, setActiveTab] = useState("order-book");
  const [quantityPriceValue, setQuantityPriceValue] = useState({
    quantity: 0,
    price: 0,
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const setQuantityPrice = (value) => {
    setQuantityPriceValue((prev) => {
      return {
        ...prev,
        quantity: value?.quantity,
        price: value?.price,
      };
    });
  };

  useEffect(() => {
    clickedSellBuyValue(quantityPriceValue);
  }, [quantityPriceValue]);

  return (
    <>
      <div className="quick-trad">
        <div className="card-header">
          {/* Make the buttons horizontal in a single row */}
          <div role="tablist">
            <button
              className="Order-Book-butten"
              onClick={() => handleTabChange("order-book")}
              role="tab"
              aria-selected={activeTab === "order-book"}
            >
              Order Book
            </button>
            <button
              className="Order-Book-butten"
              onClick={() => handleTabChange("market-trade")}
              role="tab"
              aria-selected={activeTab === "market-trade"}
            >
              Market Trade
            </button>
          </div>
        </div>

        <div>
          <div className="position-relative" id="trade-history">
            <div className="tab-content">
              {/* Order Book Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "order-book" ? "show active" : ""
                }`}
                id="order-book"
                role="tabpanel"
              >
                <OrderBook
                  selectedPair={selectedPair}
                  setQuantityPriceValue={setQuantityPrice}
                />
              </div>

              {/* Market Trade Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "market-trade" ? "show active" : ""
                }`}
                id="market-trade"
                role="tabpanel"
              >
                <MarketTrade selectedPair={selectedPair} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTradingView;
