import React, { useState } from "react";
import { verifiePanCard, sendAadharOtp, verifieAadharcard } from "../../api"; // Import the API functions
import { useNavigate } from "react-router-dom"; // Import useNavigate

function PanCardVerification({ onPanSubmit }) {
  const [panCardNumber, setPanCardNumber] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const [error, setError] = useState(false);
  const [showAadharBox, setShowAadharBox] = useState(false);
  const [aadharNumber, setAadharNumber] = useState("");
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [clientId, setClientId] = useState(""); // State to store the client_id
  const navigate = useNavigate(); // Initialize navigate function

  const handlePANChange = (e) => {
    setPanCardNumber(e.target.value);
  };

  const handlePANSubmit = async () => {
    try {
      const response = await verifiePanCard(panCardNumber); // Call API to verify PAN
      if (response.status === 200 && !response.error) {
        setVerificationMessage(response.message);
        setError(false);
        setShowAadharBox(true); // Show Aadhar box after successful PAN verification
        if (onPanSubmit) {
          onPanSubmit(panCardNumber);
        }
      } else {
        setVerificationMessage("Error verifying PAN card.");
        setError(true);
      }
    } catch (error) {
      console.error("Error verifying PAN card:", error);
      setVerificationMessage("Error verifying PAN card.");
      setError(true);
    }
  };

  const handleAadharChange = (e) => {
    setAadharNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleAadharSubmit = async () => {
    try {
      const response = await sendAadharOtp(aadharNumber); // Call API to send OTP
      if (response.status === 200 && !response.error) {
        setOtpMessage("OTP sent to your Aadhar number.");
        setShowOtpBox(true); // Show OTP input box after successful submission
        const clientId = response.result?.data?.client_id;
        console.log("clientId",clientId)
        if (clientId) {
          setClientId(clientId);
        } else {
          console.error("client_id missing in response");
        }
      } else {
        setOtpMessage("Error sending OTP.");
      }
    } catch (error) {
      console.error("Error sending Aadhar OTP:", error);
      setOtpMessage("Error sending OTP.");
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const response = await verifieAadharcard(aadharNumber, otp, clientId);
      if (response.status === 200 && !response.error) {
        setOtpMessage("Aadhar card verified successfully!");
        setError(false);
        navigate("/wallet");

      } else {
        setOtpMessage("Error verifying Aadhar card.");
        setError(true);
      }
    } catch (error) {
      console.error("Error verifying Aadhar card:", error);
      setOtpMessage("Error verifying Aadhar card.");
      setError(true);
    }
  };

  return (
    <div className="pan-card-box">
      <h5>Enter your PAN card number:</h5>
      <input
        type="text"
        className="form-control"
        name="panCardNumber"
        value={panCardNumber}
        onChange={handlePANChange}
        placeholder="PAN Card Number"
      />
      <button onClick={handlePANSubmit} className="btn-action">
        Submit PAN
      </button>

      {verificationMessage && (
        <div className={`response-message ${error ? "error" : "success"}`}>
          {verificationMessage}
        </div>
      )}

      {showAadharBox && (
        <div className="aadhar-box">
          <h5>Enter your Aadhar number:</h5>
          <input
            type="text"
            className="form-control"
            name="aadharNumber"
            value={aadharNumber}
            onChange={handleAadharChange}
            placeholder="Aadhar Number"
          />
          <button onClick={handleAadharSubmit} className="btn-action">
            Submit Aadhar
          </button>
          {otpMessage && (
            <div className="response-message success">{otpMessage}</div>
          )}
        </div>
      )}

      {showOtpBox && (
        <div className="otp-box">
          <h5>Enter the OTP sent to your Aadhar number:</h5>
          <input
            type="text"
            className="form-control"
            name="otp"
            value={otp}
            onChange={handleOtpChange}
            placeholder="Enter OTP"
          />
          <button onClick={handleOtpSubmit} className="btn-action">
            Submit OTP
          </button>
        </div>
      )}
    </div>
  );
}

export default PanCardVerification;
