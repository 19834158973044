import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Blog from "../components/blog/Blog";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import img from "../assets/images/layout/banner-04.png";
import dataBlog from "../assets/fake-data/data-blog";

import { getmarket } from "../api";

Markets.propTypes = {};

function Markets(props) {
  const [dataCrytoContents, setdataCrytoContent] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [dataBlock] = useState({
    heading: "Today’s Cryptocurrency prices",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await getmarket();
        if (response.success === true) {
          if (response.error) {
            setErrorMessage("Error: " + response.error);
          } else {
            setdataCrytoContent(response.data);
          }
        } else {
          setErrorMessage("Error: Unable to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
        setErrorMessage("An error occurred while fetching market data.");
      }
    };

    fetchMarketData();
  }, []);

  if (errorMessage) {
    return <h4>{errorMessage}</h4>;
  }

  return (
    <div className="markets home-2">
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="banner__content">
                <h2 className="title">Today’s Cryptocurrency prices</h2>
                <p className="fs-24 desc">
                  The global crypto market cap is <span>$1.86T</span>
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__image">
                <img src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="coin-list">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs1">
                <div className="content-tab1">
                  <div className="content-inner">
                    <div className="flat-tabs2">
                      <div className="content-tab2">
                        <div className="content-inner">
                          <div className="coin-list__main">
                            <div className="flat-tabs">
                              <Tabs>
                                {dataCrytoContents.map((data) => (
                                  <TabPanel key={data.id}>
                                    <div className="content-inner">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">#</th>
                                            <th scope="col">Trading Pairs</th>
                                            <th scope="col">Last Traded</th>
                                            <th scope="col">24H Change%</th>
                                            <th scope="col">24H High</th>
                                            <th scope="col">24H Low</th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {dataCrytoContents.map(
                                            (idx, index) => (
                                              <tr key={idx.id}>
                                                <th scope="row">
                                                  <span className="icon-star"></span>
                                                </th>
                                                <td>{index + 1}</td>
                                                <td>
                                                  <Link to="#">
                                                    <img
                                                      src={idx.icon}
                                                      alt="rockie"
                                                      style={{
                                                        width: "24px",
                                                        height: "24px",
                                                      }}
                                                    />
                                                    <span>{idx.name}</span>
                                                    <span className="unit">
                                                      {idx.symbol}
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td className="boild">
                                                  {idx.supply}
                                                </td>
                                                <td className="up">
                                                  {idx.usdt_price}
                                                </td>
                                                <td className="boild">
                                                  {idx.inr_price}
                                                </td>
                                                <td className="boild">
                                                  {idx.inr_price}
                                                </td>
                                                <td>
                                                  <Link
                                                    to={`/spot/${idx.symbol}-INR`}
                                                    className="btn"
                                                  >
                                                    Trade
                                                  </Link>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </TabPanel>
                                ))}
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Blog data={dataBlog} />
    </div>
  );
}

export default Markets;
