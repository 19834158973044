import React, { useState, useEffect } from "react";
import { sellOrder, getbalance } from "../../api"; // Adjust the path according to your file structure
import "./buysell.css"; // Import your custom CSS
import { useParams } from "react-router-dom";

const Sell = ({ quanityPriceValue }) => {
  const { market } = useParams();

  const [loading, setLoading] = useState(true); // State to handle loading
  const [quantity, setQuantity] = useState(""); // Set initial state to empty string
  const [price, setPrice] = useState(""); // Set initial state to empty string
  const [availableBalance, setAvailableBalance] = useState(null); // State for available balance
  const [lockedBalance, setLockedBalance] = useState(null); // State for locked balance
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [wallet_type, setWallet_type] = useState(null); // State for available balance

  useEffect(() => {
    setQuantity(quanityPriceValue?.quantity);
    setPrice(quanityPriceValue?.price);
  }, [quanityPriceValue]);

  // Function to fetch balance
  const fetchBalance = async () => {
    try {
      const response = await getbalance(market); // Fetch balance for selected pair
      if (response.success) {
        setAvailableBalance(response.data[1].balance); // Set available balance
        setLockedBalance(response.data[1].locked); // Set locked balance
        setWallet_type(response.data[1].wallet_type)

      } else {
        setErrorMessage("Failed to fetch balance.");
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
      setAvailableBalance(0); // Set available balance
      setLockedBalance(0); // Set locked balance
      // setErrorMessage('Error fetching balance.'); // Handle error case
    }
  };

  const handleLimitSubmit = async (e) => {
    e.preventDefault(); // Prevent page refresh

    // Convert price and quantity to numbers
    const rawPrice = parseFloat(price);
    const volume = parseFloat(quantity);

    // Validate price and quantity to ensure they are greater than 0
    if (rawPrice <= 0 || volume <= 0) {
      setErrorMessage("Price and quantity must be greater than 0."); // Show error message
      return; // Exit the function if validation fails
    }

    const limitOrderData = {
      symbol: market,
      raw_price: rawPrice, // Validated price
      volume: volume, // Validated quantity
    };

    // Call the API to submit the limit order
    try {
      const response = await sellOrder(
        market,
        limitOrderData.raw_price,
        limitOrderData.volume
      );
      if (response.status) {
        // If the order is successful, set the success message
        setSuccessMessage(`sell order submitted`);
        // Reset the form
        setQuantity(""); // Reset quantity input
        setPrice(""); // Reset price input
        setErrorMessage(""); // Clear any existing error messages
        // Fetch the updated balance after the successful order
        fetchBalance(); // Update balance after successful order
      } else {
        setSuccessMessage(""); // Clear success message
        // Display the message from the response
        setErrorMessage(response.message || "Failed to submit limit order.");
      }
    } catch (error) {
      console.error("Failed to submit limit order:", error);
      setErrorMessage("Failed to submit limit order: " + error.message); // Optionally handle error
    }
  };

  // Fetch user balance for the selected pair on component mount or when market changes
  useEffect(() => {
    if (market) {
      fetchBalance(); // Call the balance fetching function if a pair is selected
      setLoading(false); // Set loading to false once the data is fetched
    }
  }, [market]); // Dependency array includes market, so it fetches balance when market changes

  return (
    <div className="card quick-trad" id="trading">
      <div className="card-header">
        <div className="nav d-flex" role="tablist">
          Sell Limit
        </div>
      </div>
      <div className="card-body">
        {loading ? (
          <p>Loading...</p> // Show loading state while fetching
        ) : (
          <div className="tab-content">
            <form className="form" onSubmit={handleLimitSubmit}>
              <div className="input-group mb-3">
                <span className="input-group-text">Quantity</span>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00214"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)} // Update quantity state
                  required // Makes the field required
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">Price</span>
                <input
                  type="number"
                  className="form-control"
                  placeholder="8796"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)} // Update price state
                  required // Makes the field required
                />
              </div>
              <div className="mb-3 sub-text">
                <div className="d-flex mb-1 justify-content-between">
                  <p>Balance</p>
                  <p>
                    {availableBalance !== null
                      ? `${availableBalance.toFixed(4)}  ${wallet_type}`
                      : "Loading..."}
                  </p>
                </div>
                <div className="d-flex mb-1 justify-content-between">
                  <p>Lock Balance</p>
                  <p>
                    {lockedBalance !== null
                      ? `${lockedBalance.toFixed(4)} ${wallet_type}`
                      : "Loading..."}
                  </p>
                </div>
              </div>

              {/* Display success or error messages */}
              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}

              <div className="btn-group mb-1 w-100">
                <input
                  type="submit"
                  value="Sell"
                  className="btn btn-danger border-0"
                  style={{
                    backgroundColor: "rgb(155, 96, 96)",
                    color: "white",
                  }}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sell;
