import React, { useState, useEffect } from "react";
import { getuserorder, cancelorder } from "../../api"; // Adjust the path according to your file structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveOrders = () => {
  const [activeOrders, setActiveOrders] = useState([]);
  const [historyOrders, setHistoryOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Active_Order");
  const [visibleOrders, setVisibleOrders] = useState(5);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getuserorder();
        const active = data.filter((order) => order.order_status === 0);
        const history = data.filter(
          (order) => order.order_status === 1 || order.order_status === 3
        );

        setActiveOrders(active);
        setHistoryOrders(history);
        setLoading(false);
        toast.success("Orders fetched successfully!");
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
        toast.error("Please Login.");
      }
    };

    fetchOrders();
  }, []);

  const handleCancelOrder = async (orderId, orderType) => {
    try {
      const response = await cancelorder(orderId, orderType); // Call the cancel order API
      if (response.success) {
        toast.success("Order canceled successfully!");

        // Remove the canceled order from the activeOrders state
        setActiveOrders((prevOrders) =>
          prevOrders.filter((order) => order.orderId !== orderId)
        );
      } else {
        toast.error(`Failed to cancel the order: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error canceling order. Please try again.");
      console.error("Error canceling order:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const loadMoreOrders = () => {
    setVisibleOrders((prevVisible) => prevVisible + 5);
  };

  const styles = {
    card: { margin: "0px" },
    nav: { borderBottom: "1px solid #dee2e6" },
    navLink: {
      textDecoration: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      color: "#6c757d",
      transition: "background-color 0.3s",
      cursor: "pointer",
    },
    navLinkActive: { borderBottom: "2px solid rgb(151 189 199)" },
    table: { width: "100%" },
    showMoreBtn: {
      margin: "10px 0",
      padding: "10px",
      backgroundColor: "#599397",
      color: "#fff",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
    },
  };

  return (
    <div className="card quick-trad" style={styles.card}>
      <ToastContainer />
      <div className="card-header">
        <div className="nav d-flex" role="tablist" style={styles.nav}>
          <a
            style={{
              ...styles.navLink,
              ...(activeTab === "Active_Order" ? styles.navLinkActive : {}),
            }}
            onClick={() => setActiveTab("Active_Order")}
            role="tab"
            aria-selected={activeTab === "Active_Order"}
          >
            Active Orders ({activeOrders.length})
          </a>
          <a
            style={{
              ...styles.navLink,
              ...(activeTab === "History" ? styles.navLinkActive : {}),
            }}
            onClick={() => setActiveTab("History")}
            role="tab"
            aria-selected={activeTab === "History"}
          >
            History ({historyOrders.length})
          </a>
        </div>
      </div>

      <div className="card-body">
        <div className="tab-content">
          {activeTab === "Active_Order" && (
            <div className="tab-pane fade show active" id="Active_Order" role="tabpanel">
              <div className="table-responsive w-100">
                <table className="table table-striped table-hover mb-0" style={styles.table}>
                  <thead>
                    <tr className="text-muted">
                      <td>Symbol</td>
                      <td>Price</td>
                      <td>Volume</td>
                      <td>Executed</td>
                      <td>Remaining</td>
                      <td>Type</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {activeOrders.slice(0, visibleOrders).map((order, index) => (
                      <tr key={index}>
                        <td style={{ color: "#599397" }}>{order.symbol}</td>
                        <td style={{ color: "#599397" }}>{order.price}</td>
                        <td style={{ color: "#599397" }}>{order.volume.toFixed(4)}</td>
                        <td style={{ color: "#599397" }}>{order.total_executed.toFixed(4)}</td>
                        <td style={{ color: "#599397" }}>{order.remaining.toFixed(4)}</td>
                        <td style={{ color: order.orderType === "buy" ? "green" : "red" }}>
                          {order.orderType}
                        </td>
                        <td
                          className={`text-capitalize ${
                            order.order_status === 0 ? "text-warning" : "text-success"
                          }`}
                        >
                          {order.order_status === 0 ? "active" : "complete"}
                        </td>
                        <td
                          style={{ color: "rgb(172 85 76)", cursor: "pointer" }}
                          onClick={() => handleCancelOrder(order.orderId, order.orderType)}
                        >
                          Cancel
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {visibleOrders < activeOrders.length && (
                <button style={styles.showMoreBtn} onClick={loadMoreOrders}>
                  Show More
                </button>
              )}
            </div>
          )}

          {activeTab === "History" && (
            <div className="tab-pane fade show active" id="History" role="tabpanel">
              <div className="table-responsive w-100">
                <table className="table table-striped table-hover mb-0" style={styles.table}>
                  <thead>
                    <tr className="text-muted">
                      <td>Symbol</td>
                      <td>Price</td>
                      <td>Volume</td>
                      <td>Type</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {historyOrders.slice(0, visibleOrders).map((order, index) => (
                      <tr key={index}>
                        <td style={{ color: "#599397" }}>{order.symbol}</td>
                        <td style={{ color: "#599397" }}>{order.price}</td>
                        <td style={{ color: "#599397" }}>{order.total_executed.toFixed(4)}</td>
                        <td style={{ color: order.orderType === "buy" ? "green" : "red" }}>
                          {order.orderType}
                        </td>
                        <td
                          className={`${
                            order.order_status === 1 ? "text-success" : "text-danger"
                          }`}
                        >
                          {order.order_status === 1 ? "complete" : "canceled"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {visibleOrders < historyOrders.length && (
                <button style={styles.showMoreBtn} onClick={loadMoreOrders}>
                  Show More
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveOrders;
